body {
  margin: 0;
  font-family: 'Poppins', 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter Variable',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'PT Sans', source-code-pro, Menlo, Monaco, Consolas,
    monospace;
}

h1 {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  font-family: 'Inter';
  color: #143836;
}

h3 {
  margin: 0;
  font-weight: 600;
}

h2 {
  text-align: start;
  padding: 0px 12px;
}

p {
  margin: 0;
  margin-top: 3px;
}

td {
  padding: 0;
}


/* scrollbar for the entire app */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #F8F6EE;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 20px;
  border: 3px solid #F8F6EE;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}