.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.display-none {
  display: none;
}

a:visited {
  color: #1C9B5E !important;
  text-decoration: none;
}

.mx-3 {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-2 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-half-right {
  margin-right: 4px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 50px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 6px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-1 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 24px;
}

.d-flex {
  display: flex;
  align-items: center;
}

.font-500 {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.mt-8 {
  margin-top: 2px !important;
}

.h-112 {
  height: 112px;
}

.secondary-btn {
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #3B4D4C;
  border: 1px solid #93B5B3;
  border-radius: 6px;
  padding: 8px 12px;
  cursor: pointer;
  font-family: 'Poppins', monospace;
}

.secondary-btn:hover {
  /* background-color: rgba(20, 56, 54, 0.08); */
  background-color: #F8F6EE;
}

.primary-btn {
  background: #143836;
  font-size: 14px;
  font-weight: 600;
  color: #FFFEF8;
  border-radius: 6px;
  border: 1px solid #143836;
  padding: 8px 12px;
  cursor: pointer;
  font-family: 'Poppins', monospace;
}

.primary-btn:hover {
  background: #13614E;
}

input::placeholder,
textarea::placeholder {
  color: rgba(59, 77, 76, 0.5);
}

.multicalendar-container,
.multicalendar-header-container {
  display: flex;
}

.multicalendar-container {
  min-height: 95vh;
  background-color: #FFFEF8;
}

.multicalendar-header-container {
  height: 75px;
  align-items: center;
  background-color: #FFFEF8;
}

.search-listings,
.listings {
  background-color: #F8F6EE;
}

.listings {
  display: flex;
  flex-direction: column;
  min-width: 25%;
  width: 25%;
  padding-bottom: 24px;
}

.modal-property-container {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  margin: 8px 16px 8px 32px;
  background-color: #F8F6EE;
}

.modal-property-container:hover {
  background-color: #ECEEE5;
}

.property-container {
  display: flex;
  align-items: center;
}

.listing-container {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
}

.listing {
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ECEEE5;
  background-color: #F8F6EE;
  position: sticky;
  left: 0;
  z-index: 9;
}

.listing:hover {
  background-color: #ECEEE5;
}

.modal-property-image,
.modal-property-image-none {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ECEEE5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-property-image-none img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.modal-property-image img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.bar-property-image-none {
  margin-right: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ECEEE5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar-property-image-none img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.modal-exit {
  background: #F8F6EE;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
}

.modal-exit:hover {
  background: #ECEEE5;
}

.listing-image,
.listing-image-none {
  margin-right: 5%;
  width: 35px;
  height: 35px;
  border-radius: 25%;
  background-color: #ECEEE5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-image-none:hover,
.listing:hover .listing-image-none,
.property:hover .listing-image-none,
.property-nou:hover .listing-image-none {
  background-color: #C6F2CA;
}

.listing-image-none img {
  width: 24px;
  height: 24px;
  border-radius: 25%;
}

.listing-image img {
  width: 35px;
  height: 35px;
  border-radius: 25%;
}

.listing h3 {
  font-size: 14px;
  color: #143836;
  font-weight: 600 !important;
}

.listing p {
  font-size: 12px;
  color: #3B4D4C;
}

.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
}

.calendar-date-header,
.calendar-row {
  display: grid;
  grid-template-columns: 150px repeat(30, 150px);
  min-width: fit-content;
}

.calendar-date-header {
  position: relative;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid #ECEEE5;
  height: 50px;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.calendar-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.date-filter-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.date-filter {
  background-color: #F8F6EE;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #143836;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.date-filter .navi-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.date-filter .mx-3 {
  width: 150px;
  text-align: center;
  white-space: nowrap;
  margin-left: 20px;
  margin-right: 20px;
}

.month-dropdown-container {
  position: relative;
  width: 200px;
}

.month-dropdown {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins', monospace;
  color: #143836;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.month-dropdown-container .month-dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #FFFEF8;
  border: 1px solid #ECEEE5;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 200;
  list-style-type: none;
  padding: 0;
  margin-top: 8px;
}

.month-option {
  padding: 10px;
  color: #143836;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.month-option:hover {
  background-color: #F8F6EE;
}

.month-option.selected {
  background-color: #C6F2CA;
}

.another-button {
  padding-right: 20px;
}

.calendar-row {
  height: 75.1px;
  min-height: 75px;
  max-height: 77px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
  display: grid;
}


.calendar-row:first-child {
  height: 76.5px;
  min-height: 76.5px;
  max-height: 76.5px;
}


/*
.calendar-row:nth-child(2) {
  height: 76.2px;
  min-height: 76.2px;
  max-height: 76.2px;
} */


.today-label {
  display: inline-block;
  background: #143836;
  color: #fff;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 12px;
  margin-left: 7px;
  vertical-align: middle;
}

.listing-column-header {
  background-color: #f0f0f0;
}

.calendar-body {
  display: flex;
  flex-direction: column;
}

.listing-row-header {
  background-color: #f0f0f0;
  width: 500px;
  padding: 10px;
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.table-day-cell {
  border-right: 1px solid #ECEEE5;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #ECEEE5;
  max-width: 150px;
  /* vertical-align: top; */
}

.booking-exists {
  border-right-width: 0px;
}

.day-cell-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  min-height: 80px;
}

.day-cell {
  /* height: 75px;
  min-height: 75px;
  max-height: 75px; */
  min-height: 75px;
  border-bottom: 1px solid #ECEEE5;
  border-right: 1px solid #ECEEE5;
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
}

.day-cell-disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
  transition: opacity 0.3s ease;
}

/* .day-cell {
  position: relative;
  height: 49.5px;
  padding: 12px 0px 12px 0px;
} */

.px-l-8 {
  padding-left: 8px;
}

.booking,
.manual-block,
.manual-block-single,
.booking-single {
  padding: 4px 0px 6px 14px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  height: 57px;
}

.position-absolute {
  position: absolute;
}

.booking {
  background-color: #C6F2CA;
  z-index: 1;
  width: 92%;
}

.booking-single {
  background-color: #C6F2CA;
  z-index: 1;
  width: 95%;
}

.manual-block {
  background-color: #C5DCDB;
  width: 92%;
}

.manual-block-single {
  background-color: #C5DCDB;
  width: 95%;
}

.z-index-1 {
  z-index: 1;
}

/* .booking {
  background-color: #C6F2CA;
  width: calc(100% - 4px);
  padding: 4px 14px;
  position: absolute;
  height: 70%;
  top: 15%;
  left: 0;
  margin: 0 2px;
  border-radius: 12px;
  box-sizing: border-box;
} */

/* .left-icon:hover,
.right-icon:hover {
  width: 17px;
  height: 17px;
} */

.booking-start {
  width: 82%;
  margin-left: 20%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  /* border-top-right-radius: 12px;
  border-bottom-right-radius: 12px; */
  z-index: 2;
}

.booking-same-day {
  width: 64%;
  margin-left: 20%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 2;
}

.job-and-booking-start {
  margin-left: 30%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  z-index: 2;
}

.width-80 {
  width: 77%;
}

.width-65 {
  width: 62%;
}

.width-50 {
  width: 72%;
}

.booking-end-and-job,
.manual-end-and-manual {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 10%;
  padding: 4px 0px 6px 14px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  height: 57px;
  position: absolute;
}

.booking-end-and-job {
  background-color: #C6F2CA;
}

.manual-end-and-manual {
  background-color: #C5DCDB;
}

.booking-end {
  /* width: 30%;
  margin-right: 30%; */
  width: 50%;
  margin-right: 73%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 2;
}

.booking-end.no-jobs {
  width: 50%;
  margin-right: 75%;
}

.booking-end-overlap {
  width: 31%;
  margin-right: 30%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 2;
}

.booking-end-indicator {
  background-color: #FF5733;
  height: 100%;
  width: 3px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.booking p {
  margin: 0;
  text-transform: capitalize !important;
}

.availability>.price {
  color: #143836;
  font-weight: 500;
  font-size: 10px;
}

.availability>.stay {
  color: #93B5B3;
  font-weight: 500;
  font-size: 10px;
  align-items: center;
}

.booking-start-content {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.circle-image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #C6F2CA;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-left: -20px;
}



.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.channel-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ECEEE5;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  margin-left: -2px;
}

.channel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-icon {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.medium-icon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

.booking-details {
  margin-left: 8px;
  /* display: flex;
  flex-direction: column; */
}



.availability {
  text-align: end;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  padding-top: 8px;
  margin-left: auto;
  margin-top: -20%;
}

.day-cell.no-data {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guest-name {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 4px;
  color: #333;
}

.guest-count-label {
  display: inline-block;
  background: #1C9B5E;
  color: #FFFEF8;
  font-size: 8px;
  padding: 2px 4px;
  border-radius: 12px;
  vertical-align: middle;
}

.stay-details {
  font-size: 8px;
  color: #555;
  padding-left: 4px;
  padding-top: 1px;
}

.search-listings {
  display: flex;
  align-items: center;
  min-width: 345px;
  padding: 0px 20px;
}

.search-input {
  padding: 10px 10px 10px 40px;
  /* Add left padding to make space for the icon */
  margin: 25px 0px 25px 0px;
  font-size: 16px;
  border: 1px solid #ECEEE5;
  border-radius: 8px;
  width: 100%;
  font-family: 'PT Sans';
  background-color: #FFFEF8;
}

.search-wrapper::before {
  content: url('./components/assets/search-lg.svg');
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.search-input:focus {
  outline: none;
  border-color: #f1ecec;
}

.search-wrapper {
  position: relative;
  display: inline-block;
  width: 85%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.header button,
.header select {
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 16px;
}

.listings-header {
  padding: 0px 20px;
  font-weight: 600;
  font-size: 16px;
  background-color: #F8F6EE;
  min-width: 345px;
  position: sticky;
  left: 0;
  z-index: 1;
  text-align: start;
  border-bottom: 1px solid #ddd;
}

.date-header-cell {
  text-align: start;
  padding: 15px 8px;
  border-right: 1px solid #ddd;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background: #143836;
  min-width: 150px;
}

.date-header-cell.today {
  background: #6AD368;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 370px;
  height: 100%;
  background-color: #FFFEF8;
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  animation: slideIn 0.3s ease-out;
  z-index: 1000;
}

.close-button-wrapper {
  position: absolute;
  left: 74.5%;
  animation: slideIn 0.3s ease-out;
  z-index: 1001;
  top: 3%;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 4px 20px 12px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 17px;
  color: #143836;
  font-weight: 600;
  flex-grow: 1;
  text-transform: capitalize !important;
}

.sidebar-header-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.out-button,
.close-button,
.back-button {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.out-button {
  padding-top: 5px;
  padding-bottom: 5px;
}

.close-button {
  padding-top: 4px;
  padding-bottom: 4px;
}

.out-button:hover,
.close-button:hover,
.back-button:hover {
  background-color: #ECEEE5;
}

.out-button img {
  height: 24px;
  width: 24px;
  margin-right: 2px;
}

.close-button img {
  height: 20px;
  width: 17px;
}

.sidebar-body {
  display: flex;
}

.sidebar-tabs {
  display: flex;
  flex-direction: column;
  margin: 0px 12px 0px 0px;
  padding-left: 12px;
  border-left: 1px solid #ddd;
  min-height: 93vh;
}

.sidebar-tabs button {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  font-size: 20px;
  color: #93B5B3;
  margin: 8px 0px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.sidebar-tabs button.active {
  background-color: #C6F2CA;
  color: #13614E;
}

.sidebar-tabs button:not(.active):hover {
  background-color: #ECEEE5;
}

.sidebar-tabs img {
  transition: filter 0.3s ease;
}

.sidebar-tabs button:hover img {
  filter: brightness(0.7);
}

.sidebar-content,
.sidebar-content-job,
.sidebar-content-more-job {
  flex-grow: 1;
}

.sidebar-content p {
  margin: 10px 0;
}

.sidebar-content {
  padding: 20px 0px 0px 25px;
}

.sidebar-content-job {
  padding: 20px 25px 20px 25px;
}

.booking-content {
  display: flex;
  flex-direction: column;
}

.booking-row,
.booking-row-second,
.single-row,
.extrainfo-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 20px;
  /* width: 100%; */
}

.booking-row-second {
  margin-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEEE5;
}

.booking-row-second-link {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEEE5;
}

.job-row {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEEE5;
}

.single-row {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECEEE5;
}

.extrainfo-row {
  padding-bottom: 8px;
  /* margin-bottom: 12px; */
}

.extrainfo-row h3 {
  font-size: 17px;
  color: #143836;
  font-weight: 600;
}

.extrainfo-row p {
  margin: 0;
  font-size: 12px;
  color: #143836;
}

.extrainfo-container {
  margin-bottom: 15px;
  border-bottom: 1px solid #ECEEE5;
}


.booking-column {
  flex: 0 0 48%;
}

.booking-label {
  font-size: 10px;
  color: #3B4D4C;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.booking-label-no-bottom {
  font-size: 10px;
  color: #3B4D4C;
  margin-top: 2px;
}

.booking-value {
  color: #143836;
  font-weight: 600;
  font-size: 14px;
}

.booking-value-p {
  color: #143836;
  font-weight: 500;
  font-size: 12px;
}

.booking-value.booking-confirmed {
  color: #1C9B5E;
}

.booking-confirmed a {
  color: #1C9B5E;
  text-decoration: none;
}

.booking-confirmed a:visited {
  color: #1C9B5E;
}

.booking-value.confirmed {
  color: #17B26A;
}

.booking-value.not-confirmed {
  color: #FF5F55;
}

.booking-value.ongoing {
  color: #F4B42E;
}



.booking-value.no {
  color: #93B5B3;
}

.booking-value-non .red {
  color: #FF5F55;
}

.booking-value.underline {
  text-decoration: underline;
}

.booking-value-non {
  color: #143836;
  font-size: 14px;
}

.question-label {
  font-size: 10px;
  color: #3B4D4C;
  margin-bottom: 4px;
}

.guest-info-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.reservation-tooltip-info {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.guest-initials,
.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #13614e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 10px;
}

.profile-img {
  margin-top: 4px;
}

.profile-img.cleaner {
  background-color: #74C868;
}

.ota-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #93B5B3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.ota-img img {
  width: 100%;
  height: 100%;
}

.property-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #143836;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 10px;
}

.amenity-icon {
  width: 24px;
  height: 24px;
  color: #143836;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.reservation-icon,
.confirmed-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: #93B5B3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 8px;
}

.confirmed-icon img,
.reservation-icon img {
  height: 18px;
  width: 18px;
}

.reservation-icon .night-icon {
  height: 15px !important;
  width: 15px !important;
}

.reservation-value {
  color: #FFFFFF;
  font-size: 14px;
}

.reservation-value-small {
  color: #ECEEE5;
  font-size: 10px;
  opacity: 75%;
  margin-top: 2px;
}

.reservation-value-more-small {
  color: #ECEEE5;
  font-size: 8px;
  opacity: 75%;
  margin-top: 2px;
  font-weight: 600;
}

.reservation-value.ongoing {
  color: #F4B42E;
  font-weight: 600;
}

.reservation-value.not-confirmed {
  color: #FF5F55;
  font-weight: 600;
}

.reservation-value.confirmed {
  color: #17B26A;
  font-weight: 600;
}

.reservation-info-tooltip {
  margin-left: 8px;
}

.property,
.property-dark {
  display: flex;
  align-items: center;
}

.property {
  border-bottom: 1px solid #ddd;
}

.property-nou {
  display: flex;
  align-items: center;
}

.property {
  padding: 8px 0px 18px 0px;
}

.property-dark {
  padding: 10px 16px 10px 16px;
}

.property h3,
.property-nou h3 {
  font-size: 12px;
  color: #143836;
  margin-bottom: 4px;
}

.property p,
.property-nou p {
  font-size: 12px;
  color: #3B4D4C;
  margin: 0;
}

.property-row {
  margin-bottom: 20px;
}

.property-dark h3 {
  font-size: 12px;
  color: #FFFEF8;
  margin-bottom: 4px;
}

.property-dark p {
  font-size: 10px;
  color: #ECEEE5;
  margin: 0;
}

.auto-message-tabs {
  display: flex;
  margin-bottom: 16px;
}

.auto-message-tabs button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  margin: 0px 8px 0px 5px;
  font-family: 'Poppins', monospace;
}

.auto-message-tabs button.active {
  font-weight: 600;
  color: #3B4D4C;
  background-color: #ECEEE5;
  border-radius: 5px;
}

.schedule-row,
.history-row {
  padding: 8px 0;
}

.message-icon {
  margin-right: 10px;
  height: 10px;
  width: 10px;
  color: #93B5B3;
}

.message-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-status {
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
}

.message-status.yes {
  color: #28a745;
}

.message-status.no {
  color: #93B5B3;
}

.message-icon.yes {
  color: #6AD368;
  height: 20px;
  width: 20px;
}



/* find availability modal */

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  width: 500px;
  /* height: 66%; */
  background: #FFFEF8;
  padding: 12px 20px 0px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  border-radius: 12px;
  z-index: 1060;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px !important;
  font-weight: 600;
}

/* .close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
} */

.modal-body {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #C6F2CA;
  padding: 0px 12px 20px 12px;
}

.modal-footer {
  padding: 24px 0px 12px 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.modal-footer .form-actions,
.sidebar .modal-footer .primary-btn {
  width: 100% !important;
  padding-right: 0px !important;
}

.sidebar .block-footer .primary-btn {
  width: 100% !important;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-row .form-group {
  flex: 1;
}

.form-row .form-group:first-child {
  margin-right: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #3B4D4C;
}

.form-group input[type="date"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background-color: #F8F6EE;
  border: 1px solid #ECEEE5;
  font-family: 'PT Sans';
}

.form-group input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}


.form-group input[type="date"] {}



/* .form-group input[type="date"]::after {
  content: '\f073';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #ECEEE5;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
} */


.form-group input[type="date"]::after {
  content: '';
  background-image: url('./components/assets/calendar-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* .react-datepicker-wrapper::after {
  content: '' !important;
  background-image: url('./components/assets/calendar-icon.png') !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
} */

.react-datepicker-wrapper {
  width: 100% !important;
  cursor: pointer !important;
}

.react-datepicker-wrapper input {
  font-family: 'PT Sans' !important;
  cursor: pointer !important;
}

.react-datepicker-wrapper input[type="text"]::after {
  content: '';
  background-image: url('./components/assets/calendar-icon.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.react-datepicker__day--keyboard-selected:hover {
  background: #143836 !important;
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background: #fff !important;
}

.form-group input,
.form-group select {
  width: 100%;
  box-sizing: border-box;
  color: #143836;
  background-color: #F8F6EE;
  border: 1px solid #ECEEE5;
  border-radius: 8px;
  padding: 10px;
  font-family: 'Poppins', monospace;
  font-size: 14px;
}

.form-group select::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #143836 !important;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #aaa;
  /* Change border color */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  /* Add a subtle box shadow */
}

.form-actions {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 12px;
  padding-bottom: 14px;
}


.task-form {
  /* background: #fff; */
  border-radius: 10px;
}

.custom-select {
  position: relative;
}

.custom-select select {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #F8F6EE;
  border: 1px solid #ECEEE5;
  color: #143836;
}

.custom-select:after {
  content: '';
  background-image: url('./components/assets/down-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 5px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}



.icon-select .job-type-icon {
  position: absolute;
  left: 10px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #93B5B3;
  width: 24px;
  height: 24px;
}

.icon-select .channel-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.icon-select select {
  padding-left: 50px;
}

.initials-select .contractor-initials {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: #13614E;
  color: #fff;
  border-radius: 50%;
  /* font-weight: 600; */
  text-align: center;
  /* font-size: 14px; */
  height: 24px;
  width: 24px;
  padding: 3px;
}

.initials-select select {
  padding-left: 50px;
}

.icon-select select {
  padding-left: 40px;
}

.time-input {
  position: relative;
}

.time-input input {
  width: 50%;
  padding: 10px;
  border: 1px solid #ECEEE5;
  border-radius: 5px;
  background: #F8F6EE;
  padding-right: 40px;
}

.clock-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #93B5B3;
  height: 24px;
  width: 24px;
}

.rate-input {
  position: relative;
}

.rate-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ECEEE5;
  border-radius: 5px;
  background: #F8F6EE;
  text-align: right;
}

.rate-input::before {
  content: url('./components/assets/dollar.svg');
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  pointer-events: none;
}


.phone-input {
  position: relative;
}

.phone-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ECEEE5;
  border-radius: 5px;
  background: #F8F6EE;
  text-align: center;
}

.phone-input::before {
  content: 'AU (+61)';
  position: absolute;
  left: 10px;
  top: 11px;
  pointer-events: none;
  color: #143836;
  font-size: 14px;
}

/* .rate-input .dollar-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #93B5B3;
} */

/* For Webkit Browsers */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.no-spinner {
  -moz-appearance: textfield;
}

.time-input {
  position: relative;
}

.time-input input[type="time"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ECEEE5;
  border-radius: 5px;
  background: #F8F6EE;
  padding-right: 40px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #3B4D4C;
  /* text-shadow: 0 0 0 black; */
}

.time-input input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.time-input .clock-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #93B5B3;
  pointer-events: none;
}

textarea {
  width: 92.5%;
  padding: 10px;
  border: 1px solid #ECEEE5;
  border-radius: 5px;
  background: #F8F6EE;
  resize: none;
  font-family: 'Poppins', monospace;
}

textarea:focus {
  outline: none;
  border-color: #aaa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}


.switch-container {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
  margin-top: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ECEEE5;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #3B4D4C;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.switch-label {
  font-weight: 600;
  color: #333;
}

.switch-label.confirmed {
  color: #6AD368;
}

/*tooltip */

.tippy-box {
  background-color: #3B4D4C !important;
  padding: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

.tippy-arrow {
  color: #3B4D4C !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}

.tooltip,
.tooltip-more {
  background: #3B4D4C;
  border: 1px solid #3B4D4C;
  border-radius: 8px;
  z-index: 4;
  width: 300px;
  padding: 8px;
}

/* .tooltip::before,
.tooltip::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 16%;
  transform: translateX(-50%);
  border: 10px solid transparent;
}

.tooltip::before {
  border-bottom-color: #3B4D4C;
  border-top: none;
  top: -10px;
}

.tooltip::after {
  border-bottom-color: #3B4D4C;
  border-top: none;
  top: -8px;
} */

.tooltip-content {
  font-size: 12px;
  color: #333;
}

.tooltip-content p {
  margin: 5px 0;
}

.tooltip-content p strong {
  display: block;
  margin-bottom: 5px;
}

.tooltip-content .confirmed {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #6AD368;
}

/* .tooltip-content .confirmed-icon {
  margin-right: 8px;
} */

.tooltip-content .reservation-info {
  margin-top: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.tooltip-content .property-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.tooltip-content .property-info img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 10px;
}

.tooltip-content .property-info div {
  font-size: 12px;
}

.tooltip-content .amounts {
  display: flex;
  justify-content: space-between;
  background: #4B605E;
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
}

.tooltip-content .amounts div {
  text-align: center;
}

.tooltip-content .amounts .amount-label {
  font-size: 8px;
  color: #ECEEE5;
}

.tooltip-content .amounts .amount-value {
  font-weight: 600;
  color: #6AD368;
  font-size: 12px;
}

.tooltip-content .amounts .amount-value.not-paid {
  color: #93B5B3;
}

.tooltip-content .amounts .amount-value.due {
  color: #FF5F55;
}

.reservation-container,
.amounts-container {
  padding: 16px 12px 8px 12px;
  border-top: 1px solid #ddd;
}

.reservation-tooltip-container {
  padding: 4px 12px 0px 12px;
}

.reservation-dates {
  font-size: 14px;
  color: #ECEEE5;
  margin-bottom: 10px;
}

.time-info {
  display: flex;
}

.time-info span {
  display: flex;
  align-items: center;
  color: #93B5B3 !important;
  font-size: 10px;
}

.time-info span img {
  margin-right: 4px;
  color: #93B5B3 !important;
}

.z-index-10 {
  z-index: 8;
}

.add-options {
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F6EE;
  cursor: pointer;
  font-size: 12px;
  color: #143836;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 8;
}

.add-options p {
  margin: 1px 0;
  font-weight: 600;
}

.add-options .margin-left-30 {
  margin-left: 30%;
}

.add-options .margin-left-35 {
  margin-left: 38%;
}

.add-options p:hover {
  font-weight: 700;
}

.add-options .separator,
.separator-small-line {
  display: flex;
  align-items: center;
}

.add-options .separator {
  width: 100%;
}

.add-options .separator-small-line {
  width: 50%;
}

.add-options .separator span,
.add-options .separator-small-line span {
  color: #93B5B3;
  font-weight: normal;
  font-size: 8px;
}

.add-options .separator::before,
.add-options .separator::after,
.add-options .separator-small-line::before,
.add-options .separator-small-line::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ECEEE5;
  margin: 0 10px;
}


/* .jobs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #100;
  padding: 5px;
  border-radius: 5px;
}

.job-icon {
  width: 34px;
  height: 34px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143836;
}

.more-jobs {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

 */

/* .job-container,
.jobs-container {
  height: 57px;
  padding: 4px 0px 6px 14px;
  margin-top: 6px;
  margin-bottom: 6px;
  z-index: 2;
} */

.jobs {
  background-color: #C5DCDB;
  width: 25%;
  height: 67px;
  margin: 6px 4px 6px 4px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
}

.jobs.width-50 {
  width: 50%;
}

.jobs.width-90 {
  width: 90%;
}

/* .job-icon {
  width: 100%;
  height: 100%;
} */

.job-icon:last-child {
  margin-right: 0;
}

.job-icon.ellipsis {
  background-color: #fff;
  width: 30px;
  height: 14px;
  border-radius: 8px;
}

.job-icon img {
  height: 24px;
  width: 24px;
}

.more-jobs {
  position: absolute;
  top: -3px;
  right: -8px;
  background-color: #143836;
  color: #FFFFFF;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
}

.error-message {
  font-size: 10px;
  color: #FF5F55;
  margin: 6px 0px 6px 6px;
}

.success-message {
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}

.sm-20 {
  margin: 20px;
}

.sm-12 {
  margin-bottom: 20px;
  margin-right: 12px;
}

.submit-error-message {
  margin: 20px;
  padding: 10px;
  margin-bottom: 0;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  text-align: center;
}

.error-message>.fa-times-circle,
.success-message>.fa-check-circle {
  margin-right: 5px;
}

.repeat-every {
  display: flex;
  align-items: center;
}

.repeat-every input {
  margin-right: 0.5em;
}

.repeat-days {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}

.day-button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.day-button.selected {
  background-color: #6AD368;
  color: #fff;
}

.day-checkbox {
  background-color: #FFFEF8;
  border: 2px solid #93B5B3;
  color: #3B4D4C;
  border-radius: 5px;
  padding: 8px;
  margin: 2px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.day-checkbox.selected {
  background-color: #6AD368;
  color: #fff;
}

.day-checkbox input {
  display: none;
}

.ends-options {
  display: flex;
  flex-direction: column;
}

.ends-options label {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  cursor: pointer;
}

.ends-options input[type="radio"] {
  display: none;
}

.ends-options input[type="radio"]+.custom-radio {
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 1px solid #93B5B3;
  background-color: #F8F6EE;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.ends-options input[type="radio"]:checked+.custom-radio {
  border-color: #6AD368;
  border: 0px;
}

.ends-options input[type="radio"]:checked+.custom-radio::after {
  content: '';
  background-image: url('./components/assets/check-fill.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ends-options input[type="radio"]:checked+.custom-radio+span {
  color: #28a745;
}


.attachments {
  margin-top: 20px;
}

.attachments h3 {
  font-size: 16px;
  color: #143836;
  margin-bottom: 10px;
}

.attachment-list {
  max-height: 150px;
  overflow-y: auto;
  padding: 8px;
}

.attachment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.attachment img {
  width: 100%;
  height: 250px;
  border-radius: 8px;
}


.time-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-input-error {
  display: flex;
  align-items: center;
  gap: 40px;
}

.time-input input[type="time"] {
  flex: 1;
}

.clock-icon {
  margin-left: 10px;
  cursor: pointer;
}



.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.spinner {
  width: 45px;
  height: 45px;
  margin-top: 20%;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #c3e6cb;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.autocomplete-dropdown-container {
  border-top: none;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  color: #3B4D4C;
  background-color: FFFEF8;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.autocomplete-dropdown-container:after {
  content: '';
  background-image: url('./components/assets/down-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 5px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 10px;
  background-color: #fafafa;
}



.accordion-item {
  border: 1px solid #ECEEE5;
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;
}

.accordion-title {
  background-color: #F8F6EE;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3B4D4C;
  font-weight: 600;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 15px;
}

.accordion-content.open {
  max-height: 700px;
  padding: 0px 12px 12px 12px;
}

.sidebar-content-more-job {
  padding: 10px 15px 10px 15px;
}

.beds-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bed-item {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-bottom: 20px;
}

.bed-count {
  font-size: 14px;
  font-weight: 600;
  color: #143836;
  margin-top: 2px;
}

.bed-icon {
  width: 24px;
  height: 18px;
  color: #143836;
  margin-top: 2px;
  margin-left: 8px;
}

.bed-type {
  font-size: 10px;
  text-transform: capitalize;
  color: #143836;
  margin-bottom: 2px;
}


.main-content {
  overflow: hidden;
}

.x-icon {
  width: 12px;
  height: 12px;
}

.single-date-text {
  text-align: end;
  margin-right: 8px;
  color: #143836;
  font-weight: 600;
}

/* Styles for screen sizes between 1450px and 3000px */
@media (min-width: 1450px) and (max-width: 4000px) {
  td {
    padding: 0;
  }
}

/* Styles for screen sizes between 1200px and 1440px */
@media (min-width: 1200px) and (max-width: 1440px) {
  .listing h3 {
    font-size: 12px;
  }

  .date-header-cell {
    font-size: 12px;
  }

  .listings-header {
    font-size: 14px;
  }

  .search-input {
    font-size: 14px;
  }

  .date-filter {
    font-size: 14px;
  }

  .secondary-btn {
    font-size: 12px;
  }

  .primary-btn {
    font-size: 12px;
  }

  .sidebar-header h2 {
    font-size: 15px;
  }

  .guest-name {
    font-size: 10px;
  }

  .month-dropdown {
    font-size: 14px;
  }

  .modal-header {
    font-size: 20px;
  }

  .date-filter .navi-btn {
    margin-left: 8px;
    margin-right: 8px;
  }
}

/* Styles for screen sizes up to 1200px */
@media (max-width: 1200px) {
  .listing h3 {
    font-size: 12px;
  }

  .date-header-cell {
    font-size: 12px;
  }

  .listings-header {
    font-size: 14px;
  }

  .search-input {
    font-size: 14px;
  }

  .date-filter {
    font-size: 14px;
  }

  .secondary-btn {
    font-size: 12px;
  }

  .primary-btn {
    font-size: 12px;
  }

  .sidebar-header h2 {
    font-size: 15px;
  }

  .guest-name {
    font-size: 10px;
  }

  .month-dropdown {
    font-size: 14px;
  }

  .add-options {
    font-size: 10px;
  }

  .modal-header {
    font-size: 20px;
  }

  .listings-header,
  .search-listings {
    min-width: 320px;
  }

  .date-filter .navi-btn {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.amenities-row {
  display: flex;
  flex-wrap: wrap;
}

.amenity-column {
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.amenity-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.amenity-icon {
  width: 24px;
  height: 24px;
  color: #143836;
  margin-right: 8px;
}

.booking-label {
  font-size: 10px;
  color: #3B4D4C;
}



.custom-dropdown {
  position: relative;
  width: 100%;
}

.selected-value {
  width: 100%;
  box-sizing: border-box;
  color: #143836;
  background-color: #F8F6EE;
  border: 1px solid #ECEEE5;
  border-radius: 8px;
  padding: 8px;
  font-family: 'Poppins', monospace;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.selected-value img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #f1ecec;
  border-radius: 8px;
  background-color: #FFFEF8;
  font-family: 'Poppins', monospace;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.option img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.option:hover {
  background-color: #f0f0f0;
}

.react-datepicker__header {
  background-color: #FFFEF8 !important;
}

/* .react-datepicker-time__header,
.react-datepicker-year-header */

.react-datepicker__current-month {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.react-datepicker__day--selected {
  background: #143836 !important;
  color: #fff !important;
}

.react-datepicker__day--today,
.react-datepicker__day--keyboard-selected {
  background: none;
  color: #000;
  font-weight: 400 !important;
}

.react-datepicker__current-month {
  text-align: center;
}


/* .arrow {
  float: right;
}

.arrow.open {
  transform: rotate(180deg);
} */


.fetching-more-indicator {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: 'PT-SANS', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fetching-more-indicator {
  animation: fadeIn 0.5s ease-in-out;
}

.spinner-message {
  width: 16px;
  height: 16px;
  border: 4px solid #FFFEF8;
  border-top: 4px solid #c3e6cb;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
  margin-right: 8px;
}

.fetching-more-indicator .retry {
  width: 16px;
  height: 16px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}


.property-item.highlight {
  background-color: #e6ffe6;
  border: 2px solid #4CAF50;
  transition: transform 0.3s ease, background-color 0.3s ease;
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.property-item.fade-out {
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.property-item.highlight:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}


.date-header-cell.highlight {
  background-color: #ffeb3b;
  color: #000;
  border: 2px solid #fbc02d;
  transition: background-color 0.3s ease-in-out;
}

.date-header-cell.dim {
  opacity: 0.4;
  pointer-events: none;
  /* Disable interactions */
  transition: opacity 0.3s ease-in-out;
}

.no-properties-message {
  text-align: center;
  font-size: 1em;
  margin-top: 20px;
  color: #3B4D4C;
}

.date-picker-half-width {
  width: 50%;
}

.pointer {
  cursor: pointer;
}

.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner-container .spinner-tooltip {
  width: 20px;
  height: 20px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #6AD368;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes bounce {

  0%,
  100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
}


.hover-tooltip {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3B4D4C;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
  white-space: nowrap;
}



.img-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.img-modal-content {
  max-width: 80%;
  max-height: 80%;
  animation: slideIn 0.3s ease-out;
}

.img-modal-content img {
  width: 500px;
  height: auto;
}

tfoot {
  position: static !important;
}

thead {
  z-index: 10 !important;
}

table {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
}

td,
th,
tr {
  border-bottom: 1px solid #ECEEE5 !important;
}

.single-calendar-page {
  background: #ECEEE5;
  padding: 32px 32px 24px 32px;
}

.single-calendar-container {
  margin-top: 32px;
  background: #fffef8;
  padding: 16px;
  border-radius: 16px;
}

.single-calendar-container table {
  width: 100%;
}


.single-page-header-img-container {
  margin-right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fffef8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.single-page-header-img-container img {
  width: 40px;
  height: 40px;
}