/* .skeleton-listing {
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.skeleton-listing .listing-container {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
} */

.skeleton-image {
  width: 35px;
  height: 35px;
  border-radius: 25%;
  background-color: #ECEEE5;
  margin-right: 5%;
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-texts {
  flex: 1;
}

.skeleton-title {
  width: 60%;
  height: 14px;
  background-color: #ECEEE5;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-subtitle {
  width: 25%;
  height: 12px;
  background-color: #ECEEE5;
  border-radius: 8px;
  animation: pulse 1.5s ease-in-out infinite;
}

.calendar-skeleton-subtitle {
  width: 100%;
  height: 50px;
  background-color: #ECEEE5;
  border-radius: 12px;
  animation: pulse 1.8s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-color: #ECEEE5;
  }

  50% {
    background-color: #F5F5F5;
  }

  100% {
    background-color: #ECEEE5;
  }
}